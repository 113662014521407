import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import loadingImg from '../images/loading.gif';
import {roundDecimalValue, linkify} from '../utils';
class Results extends Component {
  render() {
    if (this.props.isAuthenticated) {
      const heading = (
        <div className="row">
          <div className="col">
            <b>Calculator results</b>
            <hr />
          </div>
        </div>
      );
      if (this.props.output !== undefined && this.props.output.result !== undefined) {
        const output = (
          <React.Fragment>
            {heading}
            <div className="select-inputs container">
              <div className="row">
                <div className="col border">
                  <p className="font-italic">
                    {' '}
                    <u>Results</u>{' '}
                  </p>
                  <p>
                    Fuel Consumption Change: <b>{roundDecimalValue(this.props.output.result)} %</b>
                  </p>
                  {this.props.output.method === 'ref' && (
                    <>
                      <p>
                        Alternative Fuel Consumption:{' '}
                        <b>
                          {roundDecimalValue(this.props.output.new_fc)} {this.props.newFcUnit}
                        </b>
                      </p>
                      <p>
                        CO2 Emissions Reference Fuel:{' '}
                        <b>
                          {roundDecimalValue(this.props.output.standard_emissions)}{' '}
                          {this.props.emissionsUnit}
                        </b>
                      </p>
                      <p>
                        CO2 Emissions Alternative Fuel:{' '}
                        <b>
                          {roundDecimalValue(this.props.output.new_emssions)}{' '}
                          {this.props.emissionsUnit}
                        </b>
                      </p>
                      <p>
                        CO2 Emissions Change:{' '}
                        <b>{roundDecimalValue(this.props.output.emissions)} %</b>
                      </p>
                    </>
                  )}

                  {this.props.output.method === 'df' ? (
                    <p>
                      CO2 Emissions (change):{' '}
                      <b>{roundDecimalValue(this.props.output.emissions)} %</b>
                    </p>
                  ) : null}
                </div>
                <div className="col border">
                  <p className="font-italic">
                    <u>Description</u>{' '}
                  </p>
                  <p className="result-item">{this.props.output.description}</p>
                </div>
              </div>
              <div className="row">
                <div className="col border">
                  <p className="font-italic">
                    {' '}
                    <u>References</u>{' '}
                  </p>
                  {this.props.output.references &&
                    this.props.output.references.map((item, key) => (
                      <li
                        className="result-item"
                        key={key}
                        dangerouslySetInnerHTML={{__html: linkify(item.name)}}
                      />
                    ))}
                </div>

                <div className="col border">
                  <p className="font-italic">
                    {' '}
                    <u>Recommendations</u>{' '}
                  </p>
                  {this.props.output.recommendations &&
                    this.props.output.recommendations.map((item, key) => (
                      <li
                        className="result-item"
                        key={key}
                        dangerouslySetInnerHTML={{__html: linkify(item.name)}}
                      />
                    ))}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
        return output;
      } else {
        const loading = this.props.calculationLoading ? (
          <div className="row d-flex justify-content-center">
            <img src={loadingImg} />
          </div>
        ) : null;
        return (
          <React.Fragment>
            {heading}
            {loading}
          </React.Fragment>
        );
      }
    } else {
      return <Redirect to="/login" />;
    }
  }
}

const mapStateToProps = state => {
  return {
    models: state.models,
    user: state.auth.user,
    output: state.models.res,
    isAuthenticated: state.auth.isAuthenticated,
    calculationLoading: state.models.calculationLoading,
  };
};

export default connect(
  mapStateToProps,
  null
)(Results);

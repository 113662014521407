import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {auth} from '../actions';
import {connect} from 'react-redux';
import advancefuel from '../images/advancefuel.png';
import aaltoLogo from '../images/aa.png';

class Header extends Component {
  state = {
    current: '',
  };

  constructor(props) {
    super(props);
    this.updateLocation = this.updateLocation.bind(this);
  }

  componentWillMount() {
    let routeName = window.location.pathname.split('/')[1];
    let secondary = window.location.pathname.split('/')[2];
    const route = secondary ? routeName + secondary : routeName;
    // this.setState({ current: routeName });
    this.updateLocation(route === '' ? 'home' : route);
  }

  updateLocation(to) {
    this.setState({current: to});
  }

  render() {
    let authentification;
    let regcal;
    if (this.props.isAuthenticated) {
      authentification = (
        <Link
          className="nav-link"
          onClick={() => {
            this.updateLocation('home');
            this.props.logout();
          }}
          to="/"
        >
          Logout
        </Link>
      );
      regcal = (
        <React.Fragment>
          <li className={'nav-item ' + (this.state.current === 'calculator' ? 'active' : '')}>
            <Link
              className="nav-link"
              onClick={() => this.updateLocation('calculator')}
              to="/calculator"
            >
              Tool
            </Link>
          </li>
          <li className={'nav-item ' + (this.state.current === 'calculations' ? 'active' : '')}>
            <Link
              className="nav-link"
              onClick={() => this.updateLocation('calculations')}
              to="/calculations"
            >
              My Simulations
            </Link>
          </li>
        </React.Fragment>
      );
    } else {
      authentification = (
        <Link className="nav-link" onClick={() => this.updateLocation('login')} to="/login">
          Login
        </Link>
      );
      regcal = (
        <li className={'nav-item ' + (this.state.current === 'registration' ? 'active' : '')}>
          <Link
            className="nav-link"
            onClick={() => this.updateLocation('registration')}
            to="/registration"
          >
            Register
          </Link>
        </li>
      );
    }

    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={advancefuel} height="30px" />
            <img src={aaltoLogo} height="40px" style={{marginLeft: '10px', marginRight: '10px'}} />
            End-Use Analyzer (EUA tool)
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li
                className={
                  'nav-item ' +
                  (this.state.current === 'home' ||
                  (this.props.isAuthenticated && this.state.current === 'login')
                    ? 'active'
                    : '')
                }
              >
                <Link className="nav-link" onClick={() => this.updateLocation('home')} to="/home">
                  Home{' '}
                </Link>
              </li>
              <li className={'nav-item ' + (this.state.current === 'hometeam' ? 'active' : '')}>
                <Link
                  className={'nav-link'}
                  onClick={() => this.updateLocation('hometeam')}
                  to="/home/team"
                >
                  Team
                </Link>
              </li>

              {regcal}

              <li className={'nav-item ' + (this.state.current === 'contact' ? 'active' : '')}>
                <Link
                  className={'nav-link'}
                  onClick={() => this.updateLocation('contact')}
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
              <li
                className={
                  'nav-item ' +
                  (this.state.current === 'login' && !this.props.isAuthenticated ? 'active' : '')
                }
              >
                {authentification}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      return dispatch(auth.logout());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux';
import {auth} from '../actions';
import {roundDecimalValue} from '../utils';

class UserCalculations extends Component {
  componentDidMount() {
    this.props.loadCalculations();
  }

  render() {
    if (this.props.calculations !== null)
      return (
        <table
          style={{width: '98%', marginLeft: '1%', marginTop: '70px'}}
          className="table bg-white"
        >
          <caption className="bg-white">List of previous Calculations</caption>
          <thead>
            <tr>
              <th scope="col">Method</th>
              <th scope="col">Result</th>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.props.calculations
              .slice(0)
              .reverse()
              .map((option, key) => {
                const method = option.definedFuel !== null ? 'defined Fuel' : 'Custom Model';
                const toCal = {
                  pathname: '/calculation/' + key,
                };
                return (
                  <tr key={key}>
                    <td>Using a {method}</td>
                    <td>{roundDecimalValue(option.results)}%</td>
                    <td>{option.date}</td>
                    <td>
                      <Link to={toCal}> View Details</Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      );
    else return <div></div>;
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    calculations: state.auth.calculation,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCalculations: () => {
      return dispatch(auth.loadCalculations());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCalculations);

import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {fuelCpCalculator} from '../actions';
import Results from './Results';
import LoadingOverlay from 'react-loading-overlay';
import Tour from 'reactour';
import loadingImg from '../images/loading.gif';

import {css} from '@emotion/core';

class Calculator extends Component {
  state = {
    inputModel: {
      inputs: {},
      model: {
        modelName: '',
        fuelConsumptionModel: '',
        modification: {
          modification: '',
          engine: {
            engine: '',
            surface: {
              surface: '',
              transportationMode: {
                mode: '',
              },
            },
          },
        },
      },
      inputType: 'ref',
      isDef: true,
      definedFuel: -1,
      standardFC: 10,
      standardFCUnit: '',
      emissionsUnit: '',
    },
    isTourOpen: true,
  };
  componentDidMount() {
    this.props.fetchModels();
  }

  closeTour = () => {
    this.setState({isTourOpen: false});
  };

  updateTransportationMode = e => {
    document.getElementById('surface').selectedIndex = 0;
    document.getElementById('engineType').selectedIndex = 0;
    document.getElementById('modification').selectedIndex = 0;
    document.getElementById('fuelmodel').selectedIndex = 0;
    const inputModel = {...this.state.inputModel};
    inputModel.model.modification.engine.surface.transportationMode.mode = e.target.value;
    inputModel.model.modification.engine.surface.surface = '';
    inputModel.model.modification.engine.engine = '';
    inputModel.model.modification.modification = '';
    inputModel.model.modelName = '';
    inputModel.model.fuelConsumptionModel = '';
    this.setState({...this.state, inputModel});
  };

  updateSurfaces = e => {
    document.getElementById('engineType').selectedIndex = 0;
    document.getElementById('modification').selectedIndex = 0;
    document.getElementById('fuelmodel').selectedIndex = 0;
    const inputModel = {...this.state.inputModel};
    inputModel.model.modification.engine.surface.surface = e.target.value;
    this.setState({...this.state, inputModel});
    inputModel.model.modification.engine.engine = '';
    inputModel.model.modification.modification = '';
    inputModel.model.modelName = '';
    inputModel.model.fuelConsumptionModel = '';
  };

  updateEngineType = e => {
    document.getElementById('modification').selectedIndex = 0;
    document.getElementById('fuelmodel').selectedIndex = 0;
    const inputModel = {...this.state.inputModel};
    inputModel.model.modification.engine.engine = e.target.value;
    this.setState({...this.state, inputModel});
    inputModel.model.modification.modification = '';
    inputModel.model.modelName = '';
    inputModel.model.fuelConsumptionModel = '';
  };

  updateModification = e => {
    document.getElementById('fuelmodel').selectedIndex = 0;
    const inputModel = {...this.state.inputModel};
    inputModel.model.modification.modification = e.target.value;
    this.setState({...this.state, inputModel});
    inputModel.model.modelName = '';
    inputModel.model.fuelConsumptionModel = '';
  };

  updateModel = e => {
    const inputModel = {...this.state.inputModel};
    for (let node of e.target.children) {
      if (node.value === e.target.value) {
        inputModel.model.modelName = node.getAttribute('data-id');
      }
    }
    inputModel.model.fuelConsumptionModel = e.target.value;
    inputModel.inputs = {};
    inputModel.definedFuel = -1;

    const selectedModel = this.props.models.models.models.find(
      model => model.modelName === inputModel.model.modelName
    );
    inputModel.standardFC = selectedModel.standard_fc;
    inputModel.standardFCUnit = selectedModel.standard_fc_unit;
    inputModel.emissionsUnit = selectedModel.emissions_unit;

    if (inputModel.inputType === 'ref') {
      this.stateInputsUpdate(inputModel);
    } else {
      this.setState({...this.state, inputModel}, () => {
        const definedFuelNode = document.getElementById('definedFuel');
        if (definedFuelNode) {
          definedFuelNode.selectedIndex = 0;
        }
      });
    }
    // check type
    // if(inputModel.  )
    // this.setState({ ...this.state, inputModel })
  };

  updateDefinedFuel = e => {
    const inputModel = {...this.state.inputModel};
    for (let node of e.target.children) {
      if (node.value === e.target.value) {
        inputModel.definedFuel = Number(node.getAttribute('data-id'));
      }
    }
    this.setState({...this.state, inputModel});
  };

  str2bool = value => {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
    }
    return value;
  };

  stateInputsUpdate(inputModel) {
    const fields = this.props.models.models.fields.filter(fld => {
      return (
        fld.model.modelName !== '' &&
        fld.model.modelName === this.state.inputModel.model.modelName &&
        fld.referencedBy !== null
      );
    });
    const inputs = {...inputModel.inputs};
    fields.forEach(element => {
      let key = 'reference';
      if (element.label in inputs) {
        const newInput = {...inputs[element.label], [key]: element.referencedBy.value};
        inputs[element.label] = newInput;
      } else {
        inputs[element.label] = {
          [key]: element.referencedBy.value,
        };
      }
      inputModel.inputs = inputs;
    });
    const newState = {...this.state, inputModel};

    this.setState(newState, () => {
      const definedFuelNode = document.getElementById('definedFuel');
      if (definedFuelNode) {
        definedFuelNode.selectedIndex = 0;
      }
    });
  }

  onRadioChange = val => {
    // empty and set the input type
    const inputModel = {...this.state.inputModel};
    inputModel.inputType = val;
    // clear Inputs
    inputModel.inputs = {};
    inputModel.definedFuel = -1;
    if (val === 'ref') {
      // update state
      this.stateInputsUpdate(inputModel);
    } else {
      this.setState({...this.state, inputModel});
    }
  };

  onRadioDefChange = val => {
    const isDef = this.str2bool(val);
    const inputModel = {...this.state.inputModel};
    inputModel.isDef = isDef;
    if (!isDef) {
      if (inputModel.inputType === 'blend') {
        inputModel.inputs = {};
        inputModel.definedFuel = -1;
        this.setState({...this.state, inputModel});
      } else this.stateInputsUpdate(inputModel);
    } else {
      inputModel.inputType = '';
      this.setState({...this.state, inputModel});
    }
  };

  //input Handler
  inputHandler = (input, value, refField) => {
    const inputModel = {...this.state.inputModel};
    const inputs = {...inputModel.inputs};
    let key = refField ? 'reference' : 'value';
    if (input in inputs) {
      const newInput = {...inputs[input], [key]: value};
      inputs[input] = newInput;
    } else {
      inputs[input] = {
        [key]: value,
      };
    }
    inputModel.inputs = inputs;
    this.setState({...this.state, inputModel});
  };

  onXChange = event => {
    const inputModel = {...this.state.inputModel};
    inputModel['x'] = parseFloat(event.target.value);
    this.setState({...this.state, inputModel});
  };

  disbaleSubmit = (mode, fields) => {
    if (mode === 'blend') {
      return this.state.inputModel.definedFuel === -1 || !('x' in this.state.inputModel);
    } else if (mode === 'fl') {
      return this.state.inputModel.definedFuel === -1;
    } else {
      const obj = this.state.inputModel.inputs;
      const filtredFieds =
        this.state.inputModel.inputType === 'rel'
          ? fields.filter(field => !field.isCarbonContent)
          : fields;
      //ref
      if (this.state.inputModel.standardFC === 0 || isNaN(this.state.inputModel.standardFC))
        return true;
      if (Object.entries(obj).length === 0 && obj.constructor === Object) return true;
      if (this.state.inputModel.inputType === 'ref') {
        return filtredFieds.some(field => !obj[field.label].value);
      }
      return filtredFieds.some(field => !(field.label in obj));
    }
  };

  sfcHandler = e => {
    const {inputModel} = this.state;
    inputModel.standardFC = parseFloat(e.target.value);
    this.setState({inputModel});
  };

  // Form Submission
  onSubmit = e => {
    e.preventDefault();
    this.props.fuelConsumption(this.state.inputModel);
  };

  render() {
    if (this.props.isAuthenticated && this.props.fetched) {
      let radioButtons = <div></div>;
      let ref = <div></div>;
      let definedFuels = <div></div>;
      let fuelBlends = <div></div>;

      if (this.state.inputModel.isDef && this.state.inputModel.model.modelName !== '') {
        definedFuels = (
          <div className="row mt-3">
            <div className="col">
              <div className="step-5 form-group">
                <label htmlFor="definedFuel" className="text-secondary">
                  Defined Fuels
                </label>
                <select
                  id="definedFuel"
                  onChange={this.updateDefinedFuel}
                  defaultValue={'DEFAULT'}
                  className="select-inputs form-control"
                >
                  <option value="DEFAULT" disabled>
                    Choose
                  </option>
                  {this.props.models.models.definedFuels
                    .filter(fl => !fl.isFuelBlend)
                    .sort(function(fl1, fl2) {
                      if (fl1.fuel < fl2.fuel) {
                        return -1;
                      }
                      if (fl1.fuel > fl2.fuel) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((option, key) => {
                      if (
                        option.fuelConsumptionModel.fuelConsumptionModel ===
                        this.state.inputModel.model.fuelConsumptionModel
                      )
                        return (
                          <option key={option.id} data-id={option.id}>
                            {option.fuel}
                          </option>
                        );
                    })}
                </select>
              </div>
            </div>

            <div style={{marginTop: '30px'}} className="col">
              <div className="form-group">
                <button
                  type="submit"
                  disabled={this.disbaleSubmit('fl', [])}
                  className="form-control btn btn-primary"
                >
                  Calculate
                </button>
              </div>
            </div>
          </div>
        );
      }

      if (
        !this.state.inputModel.isDef &&
        this.state.inputModel.model.modelName !== '' &&
        this.state.inputModel.inputType === 'blend'
      ) {
        fuelBlends = (
          <React.Fragment>
            <div className="row">
              <div className="col fuelblendop">
                <label className="text-secondary" htmlFor="fuelBlend">
                  Fuel Blends
                </label>
              </div>
              <div className="col">
                <label className="text-secondary">Put a value between 0 - 100</label>
              </div>
            </div>

            <div className="row ">
              <div className="col">
                <div className="step-5 form-group">
                  {/* <select id="fuelBlend" onChange={this.updateFuelBlend} defaultValue={'DEFAULT'} className="select-inputs form-control">
                                        <option value="DEFAULT" disabled>Fuel Blend</option>
                                        {this.props.models.models.fuelBlends.map((option, key) => {
                                            return <option key={option.id} data-id={option.id}>{option.blend}</option>
                                        })}
                                    </select> */}

                  <select
                    id="fuelBlend"
                    onChange={this.updateDefinedFuel}
                    defaultValue={'DEFAULT'}
                    className="select-inputs form-control"
                  >
                    <option value="DEFAULT" disabled>
                      Choose
                    </option>
                    {this.props.models.models.definedFuels
                      .filter(fl => fl.isFuelBlend)
                      .map((option, key) => {
                        if (
                          option.fuelConsumptionModel.fuelConsumptionModel ===
                          this.state.inputModel.model.fuelConsumptionModel
                        )
                          return (
                            <option key={option.id} data-id={option.id}>
                              {option.fuel}
                            </option>
                          );
                      })}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <input
                    type="number"
                    step="0.01"
                    max="100"
                    className=" form-control"
                    placeholder="Concentration"
                    onChange={this.onXChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <button
                    type="submit"
                    disabled={this.disbaleSubmit('blend', [])}
                    className="form-control btn btn-primary"
                  >
                    Calculate
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }

      if (
        !this.state.inputModel.isDef &&
        this.state.inputModel.model.modelName !== '' &&
        this.state.inputModel.inputType !== 'blend'
      ) {
        const row =
          this.state.inputModel.inputType === 'ref' ? (
            <div className="row">
              <div className="col">
                <label className="text-secondary">Reference fuel properties</label>
              </div>
              <div className="col">
                <label className="text-secondary">Alternative fuel properties</label>
              </div>
            </div>
          ) : null;
        const fields = this.props.models.models.fields.filter(fld => {
          return (
            fld.model.modelName !== '' &&
            fld.model.modelName === this.state.inputModel.model.modelName &&
            fld.referencedBy !== null
          );
        });

        const fc_standard = this.state.inputModel.inputType === 'ref' && (
          <div className="row mt-2">
            <div className="col input-group">
              <input
                onChange={this.sfcHandler}
                type="number"
                value={this.state.inputModel.standardFC}
                step="0.001"
                className="form-control"
                placeholder="Standard Fuel Consumption"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  {/* S.F.C [L/100km] */}
                  <b>
                    S.F.C{' '}
                    {this.state.inputModel.standardFCUnit !== '' &&
                      `[${this.state.inputModel.standardFCUnit}]`}
                  </b>
                </span>
              </div>
            </div>
          </div>
        );

        ref = (
          <React.Fragment>
            {row}
            {fields.map((option, key) => {
              if (this.state.inputModel.inputType === 'ref') {
                const result =
                  option.unit === '' ? (
                    <div className="row mt-2" key={key * 2}>
                      <div className="col">
                        <input
                          onChange={e => this.inputHandler(option.label, e.target.value, true)}
                          defaultValue={option.referencedBy.value}
                          type="number"
                          step="0.001"
                          className="form-control"
                          placeholder={option.referencedBy.label}
                        />
                      </div>
                      <div className="col">
                        <input
                          onChange={e => this.inputHandler(option.label, e.target.value, false)}
                          type="number"
                          step="0.001"
                          className="form-control"
                          placeholder={option.label}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-2" key={key * 2 + 1}>
                      <div className="col input-group">
                        <input
                          onChange={e => this.inputHandler(option.label, e.target.value, true)}
                          defaultValue={option.referencedBy.value}
                          type="number"
                          step="0.001"
                          className="form-control"
                          placeholder={option.referencedBy.label}
                        />
                        <div className="input-group-append">
                          <span style={{width: '70px'}} className="input-group-text">
                            <b>{option.unit}</b>
                          </span>
                        </div>
                      </div>
                      <div className="col input-group">
                        <input
                          onChange={e => this.inputHandler(option.label, e.target.value, false)}
                          type="number"
                          step="0.001"
                          className="form-control"
                          placeholder={option.label}
                        />
                        <div className="input-group-append">
                          <span style={{width: '70px'}} className="input-group-text">
                            <b>{option.unit}</b>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                return result;
              }
              if (this.state.inputModel.inputType === 'rel') {
                if (option.isCarbonContent) return;

                if (key % 2 === 0) {
                  const nextFieldIsCarbon = fields[key + 1] && fields[key + 1].isCarbonContent;
                  if (key + 1 === fields.length || nextFieldIsCarbon) {
                    return option.unit === '' ? (
                      <div className="row mt-2" key={fields.length * 2 + key}>
                        <div className="col">
                          <input
                            onChange={e => this.inputHandler(option.label, e.target.value, false)}
                            type="number"
                            step="0.001"
                            className="form-control"
                            placeholder={option.label}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="row mt-2" key={fields.length * 2 + key}>
                        <div className="col input-group">
                          <input
                            onChange={e => this.inputHandler(option.label, e.target.value, false)}
                            type="number"
                            step="0.001"
                            className="form-control def"
                            placeholder={option.label}
                          />
                          <div className="input-group-append">
                            <span
                              style={{width: '120px', fontSize: '14px'}}
                              className="input-group-text"
                            >
                              <b>{option.unit} Change</b>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    const option2 = fields[key + 1];
                    const col1 =
                      option.unit === '' ? (
                        <div className="col">
                          <input
                            onChange={e => this.inputHandler(option.label, e.target.value, false)}
                            type="number"
                            step="0.001"
                            className="form-control"
                            placeholder={option.label}
                          />
                        </div>
                      ) : (
                        <div className="col input-group">
                          <input
                            onChange={e => this.inputHandler(option.label, e.target.value, false)}
                            type="number"
                            step="0.001"
                            className="form-control def"
                            placeholder={option.label}
                          />
                          <div className="input-group-append">
                            <span
                              style={{width: '120px', fontSize: '14px'}}
                              className="input-group-text"
                            >
                              <b>{option.unit} Change</b>
                            </span>
                          </div>
                        </div>
                      );
                    const col2 =
                      option2.unit === '' ? (
                        <div className="col">
                          <input
                            onChange={e => this.inputHandler(option2.label, e.target.value, false)}
                            type="number"
                            step="0.001"
                            className="form-control"
                            placeholder={option2.label}
                          />
                        </div>
                      ) : (
                        <div className="col input-group">
                          <input
                            onChange={e => this.inputHandler(option2.label, e.target.value, false)}
                            type="number"
                            step="0.001"
                            className="form-control"
                            placeholder={option2.label}
                          />
                          <div className="input-group-append">
                            <span
                              style={{width: '120px', fontSize: '14px'}}
                              className="input-group-text"
                            >
                              <b>{option2.unit} Change</b>
                            </span>
                          </div>
                        </div>
                      );

                    return (
                      <div className="row mt-2" key={fields.length * 2 + key}>
                        {col1}
                        {col2}
                      </div>
                    );
                  }
                }
              }
            })}

            {fc_standard}

            {this.state.inputModel.inputType !== '' && (
              <div className="mt-3 row justify-content-md-center">
                <div className="form-group">
                  <button
                    type="submit"
                    disabled={this.disbaleSubmit('raw', fields)}
                    className="form-control btn btn-primary"
                  >
                    Calculate
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      }

      if (!this.state.inputModel.isDef && this.state.inputModel.model.modelName !== '') {
        radioButtons = (
          <div className="row">
            <div className="col">
              <div className="form-group">
                <div className="form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    onChange={e => this.onRadioChange('ref')}
                    value="ref"
                    name="exampleRadios"
                    id="exampleRadios2"
                    checked={this.state.inputModel.inputType === 'ref'}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Raw Inputs
                  </label>
                </div>

                <div className="form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    onChange={e => this.onRadioChange('rel')}
                    value="rel"
                    name="exampleRadios"
                    id="exampleRadios1"
                    checked={this.state.inputModel.inputType === 'rel'}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Relative Inputs
                  </label>
                </div>

                <div className="form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    onChange={e => this.onRadioChange('blend')}
                    value="blend"
                    name="exampleRadios"
                    id="exampleRadios3"
                    checked={this.state.inputModel.inputType === 'blend'}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios3">
                    Fuel Blend
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return (
        <React.Fragment>
          <div className="container ct">
            <h1 className="primarytext"> Fuel Consumption Calculator</h1>
            <form id="contact" onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <b>Engine Details</b>
                      <hr />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col mb-3">
                      <div className="step-1 form-group">
                        <label className="text-secondary" htmlFor="TransportaionMode">
                          Transportation Mode
                        </label>
                        <select
                          id="TransportaionMode"
                          onChange={this.updateTransportationMode}
                          defaultValue={'DEFAULT'}
                          className="select-inputs form-control"
                        >
                          <option value="DEFAULT" disabled>
                            Choose
                          </option>
                          {this.props.models.models.transportationModes.map((option, key) => {
                            return (
                              <option key={option.id} data-id={option.id}>
                                {option.mode}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col mb-3">
                      <div className="step-2 form-group">
                        <label className="text-secondary" htmlFor="surface">
                          Surface
                        </label>
                        <select
                          id="surface"
                          onChange={this.updateSurfaces}
                          defaultValue={'DEFAULT'}
                          className="select-inputs form-control"
                        >
                          <option value="DEFAULT" disabled>
                            Choose
                          </option>
                          {this.props.models.models.surfaces.map((option, key) => {
                            if (
                              option.transportationMode.mode ===
                              this.state.inputModel.model.modification.engine.surface
                                .transportationMode.mode
                            )
                              return (
                                <option key={option.id} data-id={option.id}>
                                  {option.surface}
                                </option>
                              );
                            else return null;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col mb-3">
                      <div className="step-3 form-group">
                        <label className="text-secondary" htmlFor="engineType">
                          Transport Sector
                        </label>
                        <select
                          id="engineType"
                          onChange={this.updateEngineType}
                          defaultValue={'DEFAULT'}
                          className="select-inputs form-control"
                        >
                          <option value="DEFAULT" disabled>
                            Choose
                          </option>
                          {this.props.models.models.enginetypes.map((option, key) => {
                            if (
                              option.surface.surface ===
                              this.state.inputModel.model.modification.engine.surface.surface
                            )
                              return (
                                <option key={option.id} data-id={option.id}>
                                  {option.engine}
                                </option>
                              );
                            else return null;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col mb-3">
                      <div className="step-4 form-group">
                        <label className="text-secondary" htmlFor="modification">
                          Fleet Type
                        </label>
                        <select
                          id="modification"
                          onChange={this.updateModification}
                          defaultValue={'DEFAULT'}
                          className="select-inputs form-control"
                        >
                          <option value="DEFAULT" disabled>
                            Choose
                          </option>
                          {this.props.models.models.modifications.map((option, key) => {
                            if (
                              option.engine.engine ===
                              this.state.inputModel.model.modification.engine.engine
                            )
                              return (
                                <option key={option.id} data-id={option.id}>
                                  {option.modification}
                                </option>
                              );
                            else return null;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col mb-3">
                      <div className="step-5 form-group">
                        <label className="text-secondary" htmlFor="fuelmodel">
                          Engine Type
                        </label>
                        <select
                          id="fuelmodel"
                          onChange={this.updateModel}
                          defaultValue={'DEFAULT'}
                          className="select-inputs form-control"
                        >
                          <option value="DEFAULT" disabled>
                            Choose
                          </option>
                          {this.props.models.models.models.map((option, key) => {
                            if (
                              option.fuelConsumptionModel.modification.modification ===
                              this.state.inputModel.model.modification.modification
                            )
                              return (
                                <option key={key} data-id={option.modelName}>
                                  {option.fuelConsumptionModel.fuelConsumptionModel}
                                </option>
                              );
                            else return null;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="row">
                    <div className="col">
                      <b>Fuel Inputs</b>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-secondary mb-3">Calculation Using</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={e => this.onRadioDefChange('true')}
                            value="false"
                            name="exampleRadiosx"
                            id="exampleRadiosx2"
                            checked={this.state.inputModel.isDef}
                          />
                          <label className="form-check-label" htmlFor="exampleRadiosx2">
                            Defined Fuel
                          </label>
                        </div>
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={e => this.onRadioDefChange('false')}
                            value="true"
                            name="exampleRadiosx"
                            id="exampleRadiosx1"
                            checked={!this.state.inputModel.isDef}
                          />
                          <label className="form-check-label" htmlFor="exampleRadiosx1">
                            Custom Fuel
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {definedFuels}
                  {radioButtons}
                  {ref}
                  {fuelBlends}
                </div>
              </div>
              <Results
                newFcUnit={this.state.inputModel.standardFCUnit}
                emissionsUnit={this.state.inputModel.emissionsUnit}
              />
            </form>
          </div>

          <Tour steps={steps} isOpen={this.state.isTourOpen} onRequestClose={this.closeTour} />
        </React.Fragment>
      );
    } else {
      if (this.props.isAuthenticated) {
        return (
          <div className="loading-container">
            <img className="loading-calculator" src={loadingImg} />
          </div>
        );
      } else {
        return <Redirect to="/login" />;
      }
    }
  }
}

const steps = [
  {
    selector: '.step-1',
    content: 'Select a Transportation Mode. This might be Land, Water, Air...',
  },
  {
    selector: '.step-2',
    content: 'Then Select a surface. This might be Road, Off-Road...',
  },
  {
    selector: '.step-3',
    content: 'Then Select the Transport Sector. Light Duty, Heavy-Duty...',
  },
  {
    selector: '.step-4',
    content: 'Then Specify if the Engine is Modified or Not',
  },
  {
    selector: '.step-5',
    content: 'And finally select an Engine Type',
  },
];

const mapStateToProps = state => {
  return {
    models: state.models,
    user: state.auth.user,
    output: state.models.res,
    isAuthenticated: state.auth.isAuthenticated,
    fetched: state.models.fetched,
    calculationLoading: state.models.calculationLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchModels: () => dispatch(fuelCpCalculator.fetchModels()),
    fuelConsumption: inputModel => dispatch(fuelCpCalculator.fuelConsumption(inputModel)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calculator);

import React from 'react';
import background from '../images/aalto.jpg';
export const Contact = () => {
  return (
    <div className="container contact_container">
      <div className="img_contact"></div>
      <div class="bg-text">
        <h1 style={{fontSize: '50px'}}>Aalto University, School of Engineering </h1>
        <h2>Department of Mechanical Engineering</h2>
        <h2> Research Group of Energy Conversion</h2>

        <p>Mail: P.O.Box 14300, FI-00076 Aalto, Finland</p>
        <p>Visiting address: Sähkömiehentie 4, 02150 Espoo</p>
        <p>
          {' '}
          <a href="https://youtu.be/mK41f24lX1k">https://youtu.be/mK41f24lX1k</a>{' '}
        </p>
        <p>yuri.kroyan@aalto.fi </p>
        <p>michal.wojcieszyk@aalto.fi</p>
      </div>
    </div>
  );
};

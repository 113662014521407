import React from 'react';
import eu from '../images/eu.png';
export const Footer = () => {
  return (
    <footer>
      <div className="center-contents">
        <img className="eu-logo" src={eu} width="50px" height="36px" />
        <span className="text">
          This project has received funding from the European Union’s Horizon 2020 research and
          innovation programme under grant agreement N.º764799.
        </span>
      </div>
    </footer>
  );
};

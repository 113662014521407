const initialState = {
  models: [],
  res: {
    result: undefined,
    emissions: '',
    message: '',
    description: '',
    references: [],
  },
  fetched: false,
  calculationLoading: false,
};

export default function models(state = initialState, action) {
  switch (action.type) {
    case 'MODELS_FETCHED':
      return {...state, models: action.data, fetched: true};
    case 'CALCULATION_SUCCESSFUL':
      return {...state, res: action.data, calculationLoading: false};
    case 'Calculation_LOADING':
      return {...state, res: action.data, calculationLoading: true};
    default:
      return state;
  }
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {roundDecimalValue} from '../utils';

class Calculation extends Component {
  render() {
    const index = this.props.calculations.length - 1 - this.props.match.params.calId;
    const calObj = this.props.calculations[index];
    const fuelConspObj =
      calObj.definedFuel !== null
        ? calObj.definedFuel.fuelConsumptionModel
        : calObj.model.fuelConsumptionModel;
    const method = calObj.definedFuel !== null ? 'defined Fuel' : 'Custom Model';
    const description =
      calObj.definedFuel !== null ? calObj.definedFuel.description : calObj.model.description;
    return (
      <div className="hvc card w-50">
        <div className="card-body">
          <h5 className="card-title">Calculation on {calObj.date}</h5>
          <p className="card-text">Using {method}</p>
          <p className="card-text">
            <b>Transportation Mode</b>{' '}
            {fuelConspObj.modification.engine.surface.transportationMode.mode}
          </p>
          <p className="card-text">
            <b>Surface</b> {fuelConspObj.modification.engine.surface.surface}
          </p>
          <p className="card-text">
            <b>Engine</b> {fuelConspObj.modification.engine.engine}
          </p>
          <p className="card-text">
            <b>Fleet Type</b> {fuelConspObj.modification.modification}
          </p>
          <p className="card-text">
            <b>Fuel Consumption Model</b> {fuelConspObj.fuelConsumptionModel}
          </p>
          <p className="card-text">
            <b>Fuel Consumption Change</b> {roundDecimalValue(calObj.results)} %
          </p>
          {calObj.new_fc && (
            <p className="card-text">
              <b>Alternative Fuel Consumption</b> {roundDecimalValue(calObj.new_fc)}{' '}
              {calObj.model.standard_fc_unit}
            </p>
          )}
          {calObj.standard_emissions && (
            <p className="card-text">
              <b>CO2 Emissions Reference Fuel</b> {roundDecimalValue(calObj.standard_emissions)}{' '}
              {calObj.model.emissions_unit}
            </p>
          )}
          {calObj.new_emssions && (
            <p className="card-text">
              <b>CO2 Emissions Alternative Fuel</b> {roundDecimalValue(calObj.new_emssions)}{' '}
              {calObj.model.emissions_unit}
            </p>
          )}
          {calObj.emissions && (
            <p className="card-text">
              <b>CO2 Emissions Change</b> {roundDecimalValue(calObj.emissions)} %
            </p>
          )}
          <p className="card-text">
            <b>Description</b> {description}
          </p>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    calculations: state.auth.calculation,
  };
};
export default connect(
  mapStateToProps,
  null
)(Calculation);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {auth} from '../actions';
import {Redirect} from 'react-router-dom';
import logo from '../images/adv.png';

class Login extends Component {
  state = {
    username: '',
    password: '',
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.clearError();
    this.props.login(this.state.username, this.state.password);
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    return (
      <div className="inner-container">
        <img src={logo} className="mt-4 App-logo" alt="logo" />
        <form className="form-signin text-center" onSubmit={this.onSubmit}>
          {this.props.errors.length > 0 &&
            this.props.errors.map(error => (
              <div className="alert alert-danger" role="alert" key={error.field}>
                {error.message}
              </div>
            ))}
          <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
          <label htmlFor="inputEmail" className="sr-only">
            Username
          </label>
          <input
            type="text"
            id="inputEmail"
            className="form-control"
            onChange={e => this.setState({username: e.target.value})}
            placeholder="Your Username"
            required
            autoFocus
          />
          <label htmlFor="inputPassword" className="sr-only">
            Password
          </label>
          <input
            type="password"
            id="inputPassword"
            className="form-control mt-3"
            onChange={e => this.setState({password: e.target.value})}
            placeholder="Password"
            required
          />
          <div className="checkbox mb-3">
            <label>
              <input type="checkbox" value="remember-me" /> Remember me
            </label>
          </div>
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Sign in
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => {
      return dispatch(auth.login(username, password));
    },
    clearError: () => {
      return dispatch(auth.clearError());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

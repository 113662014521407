import React, {Component} from 'react';
import {connect} from 'react-redux';
import p1 from '../images/yuri.png';
import p2 from '../images/michal.jpeg';
import p3 from '../images/ayoub.jpg';
import p4 from '../images/martti.png';
import p5 from '../images/ossi.jpg';
import p6 from '../images/kai.jpeg';
import video1 from '../videos/advancefuel.mp4';
import video2 from '../videos/aalto.mp4';
class Home extends Component {
  videoEvents = [];

  handleSlideButton = e => {
    this.refs.firstVideo.muted = true;
    this.refs.secondVideo.muted = true;
    this.videoEvents.forEach(event => {
      event.target.mute();
    });
  };
  jumpToTeamSection() {
    const jump = this.props.match.params.jump;
    if (jump) {
      document.getElementById('team').scrollIntoView();
    }
  }
  componentDidUpdate() {
    this.jumpToTeamSection();
  }

  componentDidMount() {
    this.jumpToTeamSection();
  }

  _onReady = event => {
    this.videoEvents.push(event);
  };

  render() {
    return (
      <div>
        <header>
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
            data-interval="false"
          >
            <ol className="carousel-indicators" style={{marginBottom: '55px'}}>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            </ol>
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active videoWrapper">
                <video ref="firstVideo" controls autoPlay muted loop>
                  <source src={video1} type="video/mp4" />
                  Your browser does not support the video Elements.
                </video>
              </div>

              <div className="carousel-item videoWrapper">
                <video ref="secondVideo" controls autoPlay muted loop>
                  <source src={video2} type="video/mp4" />
                  Your browser does not support the video Elements.
                </video>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
              style={{height: '80%'}}
              onClick={this.handleSlideButton}
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
              onClick={this.handleSlideButton}
              style={{height: '80%'}}
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </header>

        <section className="py-5">
          <div className="container">
            <h1 className="featurette-heading">
              Prediction of fuel consumption and GHG emissions for alternative fuels in various
              modes of transportation.
            </h1>
            <hr />
            <p>
              The research group of Energy Conversion from Aalto University is providing market
              stakeholders with state-of-art knowledge and sophisticated, user-friendly tool with
              integrated calculators, standards, and recommendations to accelerate the
              implementation of advanced biofuels into the market. The tool has been financed by the
              ADVANCEFUEL EU H2020 project GRANT NUMBER 764799 (
              <a target="_blank" rel="noopener noreferrer" href="http://www.advancefuel.eu/">
                www.advancefuel.eu
              </a>
              )
            </p>
            <p>
              The mission of ADVANCEFUEL project is to facilitate the commercialization of advanced
              renewable transport fuels to contribute to the achievement of the EU’s renewable
              energy targets, and reduce carbon emissions in the transport sector to 2030 and
              beyond.
            </p>
          </div>
        </section>
        <h1 id="team" className="display-4 team">
          Authors
        </h1>
        <hr width="50%" className="mb-4" />

        <div className="container marketing">
          <div className="row">
            <div className="col-lg-4">
              <img
                className="rounded-circle"
                src={p1}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2>Yuri Kroyan</h2>
              <p>Doctoral Candidate, M.Sc. Development of the concept, models and the tool.</p>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-secondary"
                  href="https://www.linkedin.com/in/ykroyan/"
                  role="button"
                >
                  Contact &raquo;
                </a>
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="rounded-circle"
                src={p2}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2>Michal Wojcieszyk</h2>
              <p>Doctoral Candidate, M.Sc. Development of the concept, models and the tool.</p>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-secondary"
                  href="https://www.linkedin.com/in/mwojcieszyk/"
                  role="button"
                >
                  Contact &raquo;
                </a>
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="rounded-circle"
                src={p3}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2>Ayoub Bani</h2>
              <p>M.Sc. Development and deployment of the software.</p>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-secondary"
                  href="https://www.linkedin.com/in/ayoubbani/"
                  role="button"
                >
                  Contact &raquo;
                </a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <img
                className="rounded-circle"
                src={p4}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2>Martti Larmi</h2>
              <p>Professor, Project Supervisor</p>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-secondary"
                  href="https://www.linkedin.com/in/martti-larmi-207a8141/"
                  role="button"
                >
                  Contact &raquo;
                </a>
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="rounded-circle"
                src={p5}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2>Ossi Kaario</h2>
              <p>Senior Scientist, Project Advisor</p>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-secondary"
                  href="https://www.linkedin.com/in/ossi-kaario-65708556/"
                  role="button"
                >
                  Contact &raquo;
                </a>
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="rounded-circle"
                src={p6}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2>Kai Zenger</h2>
              <p>Senior Lecturer, Modeling Advisor</p>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-secondary"
                  href="https://www.linkedin.com/in/kai-zenger-bb07307/?originalSubdomain=fi"
                  role="button"
                >
                  Contact &raquo;
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  };
};
export default connect(
  mapStateToProps,
  null
)(Home);

import React, {Component} from 'react';
import {auth} from '../actions';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import logo from '../images/adv.png';

class RegistrationForm extends Component {
  state = {
    username: '',
    email: '',
    password: '',
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.clearError();
    this.props.register(this.state.username, this.state.email, this.state.password);
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    return (
      <div className="inner-container">
        <img src={logo} className="mt-4 App-logo" alt="logo" />

        <form className="form-signin text-center" onSubmit={this.onSubmit}>
          {this.props.errors.length > 0 &&
            this.props.errors.map(error => (
              <div className="alert alert-danger" role="alert" key={error.field}>
                {error.message}
              </div>
            ))}
          <h1 className="h3 mb-3 font-weight-normal">Register your profile</h1>
          <label htmlFor="exampleInputUsername" className="sr-only">
            Username
          </label>
          <input
            type="text"
            onChange={e => this.setState({username: e.target.value})}
            className="form-control"
            id="exampleInputUsername"
            aria-describedby="usernameHelp"
            placeholder="Enter username"
          />
          <label htmlFor="exampleInputEmail1" className="sr-only">
            Email address
          </label>
          <input
            type="email"
            onChange={e => this.setState({email: e.target.value})}
            className="form-control mt-3"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter email"
          />
          {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
          <label htmlFor="exampleInputPassword1" className="sr-only">
            Password
          </label>
          <input
            type="password"
            onChange={e => this.setState({password: e.target.value})}
            className="form-control mt-3"
            id="exampleInputPassword1"
            placeholder="Password"
          />

          <button type="submit" className="btn btn-primary">
            Register
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    register: (username, email, password) => dispatch(auth.register(username, email, password)),
    clearError: () => {
      return dispatch(auth.clearError());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationForm);

import React, {Component} from 'react';

import {BrowserRouter as Router, Route} from 'react-router-dom';

import RegistrationForm from './components/RegistrationForm';
import Home from './components/Home';
import './App.css';
import Header from './components/Header';
import {Footer} from './components/Footer';
import {Contact} from './components/Contact';
import Login from './components/Login';
import Calculator from './components/Calculator';
import UserCalculations from './components/UserCalculations';
import Calculation from './components/Calculation';
import {Provider, connect} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {PersistGate} from 'redux-persist/integration/react';

import reducer from './reducers';

import {auth} from './actions';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
let persistor = persistStore(store);

class RootComponent extends Component {
  componentDidMount() {
    this.props.clearError();
    this.props.loadUser();
    // this.props.loadCalculations();
  }

  render() {
    return (
      <Router>
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/home/:jump?" component={Home} />
        <Route exact path="/calculator" component={Calculator} />
        <Route exact path="/calculations" component={UserCalculations} />
        <Route path="/calculation/:calId" component={Calculation} />
        <Route path="/contact" component={Contact} />

        <Route path="/registration" component={RegistrationForm} />
        <Route exact path="/login" component={Login} />
        <Footer />
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadUser: () => {
      return dispatch(auth.loadUser());
    },
    loadCalculations: () => {
      return dispatch(auth.loadCalculations());
    },
    clearError: () => {
      return dispatch(auth.clearError());
    },
  };
};

let RootContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RootComponent);

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <RootContainer />
        </PersistGate>
      </Provider>
    );
  }
}

import {auth} from '.';

export const fetchModels = () => {
  return (dispatch, getState) => {
    let headers = {'Content-Type': 'application/json'};
    const token = getState().auth.token;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }
    return fetch('https://advancefueltoolapi.azurewebsites.net/api/features/', {headers})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          });
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'MODELS_FETCHED', data: res.data});
          return res.data;
        } else if (res.status >= 400 && res.status < 500) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      });
  };
};

export const fuelConsumption = inputModel => {
  return (dispatch, getState) => {
    dispatch({type: 'Calculation_LOADING'});
    let headers = {'Content-Type': 'application/json'};
    const token = getState().auth.token;

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }
    let body = JSON.stringify(inputModel);
    return fetch('https://advancefueltoolapi.azurewebsites.net/api/fuelconsumption/', {
      headers,
      body,
      method: 'POST',
    })
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            dispatch(auth.loadCalculations());
            return {status: res.status, data};
          });
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'CALCULATION_SUCCESSFUL', data: res.data});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'CALCULATION_ERROR', data: res.data});
          throw res.data;
        } else {
          dispatch({type: 'CALCULATION_FAILED', data: res.data});
          throw res.data;
        }
      });
  };
};

export const loadUser = () => {
  return (dispatch, getState) => {
    dispatch({type: 'USER_LOADING'});

    const token = getState().auth.token;

    let headers = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers['Authorization'] = `Token ${token}`;

      return fetch('https://advancefueltoolapi.azurewebsites.net/api/user/', {headers})
        .then(res => {
          if (res.status < 500) {
            return res.json().then(data => {
              return {status: res.status, data};
            });
          } else {
            throw res;
          }
        })
        .then(res => {
          if (res.status === 200) {
            dispatch({type: 'USER_LOADED', user: res.data});
            return res.data;
          } else if (res.status >= 400 && res.status < 500) {
            dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
            throw res.data;
          }
        });
    }
  };
};

export const loadCalculations = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    let headers = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers['Authorization'] = `Token ${token}`;

      return fetch('https://advancefueltoolapi.azurewebsites.net/api/calculation/', {headers})
        .then(res => {
          if (res.status < 500) {
            return res.json().then(data => {
              return {status: res.status, data};
            });
          } else {
            throw res;
          }
        })
        .then(res => {
          if (res.status === 200) {
            dispatch({type: 'CALCULATION_LOADED', calculation: res.data});
            return res.data;
          } else if (res.status >= 400 && res.status < 500) {
            dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
            throw res.data;
          }
        });
    }
  };
};
export const clearError = () => {
  return (dispatch, getState) => {
    dispatch({type: 'CLEAR_ERROR'});
  };
};
export const login = (username, password) => {
  return (dispatch, getState) => {
    let headers = {'Content-Type': 'application/json'};
    let body = JSON.stringify({username, password});

    return fetch('https://advancefueltoolapi.azurewebsites.net/api/login/', {
      headers,
      body,
      method: 'POST',
    })
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          });
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'LOGIN_SUCCESSFUL', data: res.data});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        } else {
          dispatch({type: 'LOGIN_FAILED', data: res.data});
          throw res.data;
        }
      });
  };
};

export const register = (username, email, password) => {
  return (dispatch, getState) => {
    let headers = {'Content-Type': 'application/json'};
    let body = JSON.stringify({username, email, password});

    return fetch('https://advancefueltoolapi.azurewebsites.net/api/register/', {
      headers,
      body,
      method: 'POST',
    })
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          });
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'REGISTRATION_SUCCESSFUL', data: res.data});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        } else {
          dispatch({type: 'REGISTRATION_FAILED', data: res.data});
          throw res.data;
        }
      });
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    let headers = {'Content-Type': 'application/json'};
    const token = getState().auth.token;
    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }
    fetch('https://advancefueltoolapi.azurewebsites.net/api/logout/', {
      headers,
      body: '',
      method: 'POST',
    })
      .then(res => {
        if (res.status === 204) {
          return {status: res.status, data: {}};
        } else if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          });
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 204) {
          dispatch({type: 'LOGOUT_SUCCESSFUL'});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      });
  };
};
